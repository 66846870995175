import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviation',
  standalone: true,
})
export class AbbreviationPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return '??';
    }
    const checkTokens = value.indexOf(' ') !== -1;
    const abbreviation =
      value.substring(0, checkTokens ? 1 : 2) + (checkTokens ? value.charAt(value.lastIndexOf(' ') + 1) : '');

    return abbreviation.toLocaleUpperCase();
  }
}
