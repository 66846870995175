import { Component, OnInit, booleanAttribute, input, signal } from '@angular/core';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { ClickMenuConfiguration, ClickMenuItem, ClickMenuItemGroup } from '../click-menu/click-menu.types';
import { ClickMenuComponent } from '../click-menu/click-menu/click-menu.component';
import { GroupedClickMenuComponent } from '../click-menu/grouped-click-menu/grouped-click-menu.component';

@Component({
  selector: 'coach-dropdown',
  standalone: true,
  imports: [ClickMenuComponent, GroupedClickMenuComponent, ClickOutsideDirective],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnInit {
  menuItems = input<ClickMenuItem[]>();
  groupedMenuItems = input<ClickMenuItemGroup[]>();
  config = input<ClickMenuConfiguration>({ anchorPoint: ['bottom', 'right'], animation: 'from-top' });
  round = input(false, { transform: booleanAttribute });
  padded = input(false, { transform: booleanAttribute });
  styled = input(false, { transform: booleanAttribute });
  tabIndex = input(0);

  isOpen = signal(false);

  toggle(force?: boolean) {
    this.isOpen.update(state => (force !== undefined ? force : !state));
  }

  ngOnInit() {
    if (this.menuItems() && this.groupedMenuItems()) {
      throw new Error('DropdownComponent: Pick either menuItems or groupedMenuItems, not both.');
    }
  }
}
