import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { UserConfiguration } from '@shared/types/user.types';
import { filter, fromEvent, map, shareReplay } from 'rxjs';
import { ApiService } from './api.service';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  #api = inject(ApiService);
  #auth = inject(AuthService);
  #device = inject(DeviceService);

  isFullScreen = signal(false);
  showBackdrop = signal(false);

  navigation = {
    enabled: computed(() => this.#auth.isLoggedIn() && this.#auth.visibleToCoach()),
    toggled: signal(false),
    isOverlay: computed(() => this.#device.isTablet() || this.isFullScreen()),
  };

  #isBackdropActive = computed(() => this.showBackdrop() || this.navigation.toggled());

  documentClick$ = fromEvent<PointerEvent>(document, 'click');
  keyPressed$ = fromEvent<KeyboardEvent>(document, 'keydown');
  escapePressed$ = this.keyPressed$.pipe(filter(event => event.key === 'Escape'));

  userConfiguration$ = this.#api.get<UserConfiguration>('users/configuration').pipe(shareReplay(1));
  teamId$ = this.userConfiguration$.pipe(
    map(config => {
      const teams = config.teams || [];
      const coachTeam = teams.find(team => team.role === 'coach');
      return coachTeam?.team_id ?? null;
    })
  );

  constructor() {
    this.#device.resize$.subscribe(() => {
      this.toggleNavigation(false);
    });
    this.escapePressed$.subscribe(() => {
      this.backdropClicked();
    });

    effect(() => (document.body.style.overflow = this.#isBackdropActive() ? 'hidden' : ''));
  }

  toggleNavigation(state?: boolean) {
    this.navigation.toggled.update(toggled => (state !== undefined ? state : !toggled));
  }

  backdropClicked() {
    this.toggleNavigation(false);
    this.showBackdrop.set(false);
  }
}
