<div class="toast" [class]="type()">
  <coach-icon [icon]="icon()" size="s" class="icon" />
  <div class="content">
    @if (title(); as title) {
      <div class="title">{{ title }}</div>
    }
    @if (description(); as description) {
      <div class="description">{{ description }}</div>
    }
  </div>
</div>
