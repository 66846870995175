<button
  class="opener"
  [tabIndex]="tabIndex()"
  [class.round]="round()"
  [class.padded]="padded()"
  [class.styled]="styled()"
  (click)="toggle()"
  (clickOutside)="toggle(false)">
  <ng-content />
  @if (isOpen()) {
    @if (menuItems()) {
      <coach-click-menu [items]="menuItems()!" [config]="config()" />
    }
    @if (groupedMenuItems()) {
      <coach-grouped-click-menu [items]="groupedMenuItems()!" [config]="config()" />
    }
  }
</button>
