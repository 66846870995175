import { ActionType, AnchorPoint, AnimationDirection, HorizontalAnchorPoint } from '@shared/types/generic.types';
import { IconName, IconSize } from '../icon/icon.component';

export type ClickMenuConfiguration = {
  anchorPoint?: AnchorPoint | AnchorPoint[];
  animation?: AnimationDirection;
  radioButtons?: boolean;
};

export type ClickMenuItem = {
  id: string;
  title?: string;
  i18n?: string;
  action?: () => void;
  subtitle?: string;
  class?: ActionType;
  icon?: IconName;
  iconSize?: IconSize;
  iconPosition?: HorizontalAnchorPoint;
  image?: string;
  active?: boolean;
};

export type ClickMenuItemGroup = {
  id: string;
  title?: string;
  i18n?: string;
  items: ClickMenuItem[];
};

export const CLICK_MENU_DEFAULT_CONFIGURATION: ClickMenuConfiguration = {
  anchorPoint: ['top', 'left'],
  animation: 'from-bottom',
};
