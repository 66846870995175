import { Component, input } from '@angular/core';
import { StaggeredAnimationDirective } from '@shared/directives/staggered-animation.directive';
import { ClickMenuItemComponent } from '../click-menu-item/click-menu-item.component';
import { CLICK_MENU_DEFAULT_CONFIGURATION, ClickMenuConfiguration, ClickMenuItem } from '../click-menu.types';

@Component({
  selector: 'coach-click-menu',
  standalone: true,
  imports: [ClickMenuItemComponent, StaggeredAnimationDirective],
  templateUrl: './click-menu.component.html',
  styleUrls: ['./click-menu.component.scss'],
})
export class ClickMenuComponent {
  items = input.required<ClickMenuItem[]>();
  config = input<ClickMenuConfiguration, ClickMenuConfiguration>(CLICK_MENU_DEFAULT_CONFIGURATION, {
    transform: (config: ClickMenuConfiguration) => ({ ...CLICK_MENU_DEFAULT_CONFIGURATION, ...config }),
  });
}
