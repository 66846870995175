import { Directive, ElementRef, inject, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UtilityService } from '@shared/services/utility.service';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  clickOutside = output<void>();
  elementRef = inject(ElementRef);
  util = inject(UtilityService);

  constructor() {
    this.util.documentClick$.pipe(takeUntilDestroyed()).subscribe(event => {
      const target = event.target as HTMLElement;
      const clickedInside = this.elementRef.nativeElement.contains(target);
      if (!clickedInside) {
        this.clickOutside.emit();
      }
    });
  }
}
