import { NgTemplateOutlet } from '@angular/common';
import { Component, input } from '@angular/core';
import { AbbreviationPipe } from '@shared/pipes/abbreviation.pipe';
import { ClickMenuItem, ClickMenuItemGroup } from '../click-menu/click-menu.types';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'coach-avatar',
  standalone: true,
  imports: [NgTemplateOutlet, DropdownComponent, IconComponent, AbbreviationPipe],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
  name = input.required<string>();
  menuItems = input<ClickMenuItem[]>();
  groupedMenuItems = input<ClickMenuItemGroup[]>();
}
